import moment from "moment";
import store from "shared/stores/store";
import api, { unwrapAxiosResult } from "shared/utils/api";
import { API_URLs } from "shared/utils/constants";
import { getBirCode } from "shared/utils/utils";
import { UploadFilePayload } from './types';

const FINANCIAL_MONITORING_URI = (
    url: string
) => `${API_URLs.POSTGRE.ORDERS}/bulk-orders?url=${url}`

function getFileExtension(filename: string) {
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
}

export const uploadFileExadis = (payload: UploadFilePayload) => {
    const user = store.getState().rp4.login.user;

    const birCode = getBirCode();
    const formData = new FormData();
    const lastname = user?.lastName;
    const firstname = user?.firstName;
    const email = payload.email;
    const date = moment().format("DDMMYYYY[_]HHmm")
    const fileExtension = getFileExtension(payload.file.name)
    let url: string = birCode + "_" + lastname + "_" + firstname + "_" + email + "_" + date + "." + fileExtension;


    formData.append("file", payload.file)

    return unwrapAxiosResult<UploadFilePayload>(
        api.postForm<UploadFilePayload>(
            FINANCIAL_MONITORING_URI(url),
            formData
        ))
}

